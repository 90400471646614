import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { reset } from "../../app/store/slice/counterSlice";
import LogoutIcon from "@mui/icons-material/Logout";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import BugReportIcon from "@mui/icons-material/BugReport";
import { baseUrlReducer } from "../../redux/counterSlice";
import { APP_URL } from "../../app/config";
import { setBaseURL } from "../../app/utlils";
import useSessionStorage from "../../hooks/useSessionStorage";

export default function AccountMenu() {
  const [currentURL, setCurrentURL] = useSessionStorage(
    "baseURL",
    "https://app.blaackforestcakes.com/api/"
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { baseURL } = useSelector((state) => state.counter);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        sessionStorage.clear();
        localStorage.clear();
        dispatch(reset());
        navigate("/");
        toast.success("Logged out successfully");
      })
      .catch((error) => {
        // An error happened.
      });
  };
  const handleDevMode = () => {
    const DEV_URL = "https://dev.blaackforestcakes.com/api/";

    if (currentURL && currentURL.includes("app")) {
      setCurrentURL(DEV_URL);
    } else {
      setCurrentURL(APP_URL);
    }
    window.location.reload();
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 30, height: 30 }}>A</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: 200,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 2,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem onClick={handleClose}>
          <Avatar sx={{ mr: 1 }} /> Admin
        </MenuItem> */}
        <MenuItem onClick={handleDevMode}>
          <BugReportOutlinedIcon sx={{ mr: 2 }} />
          {currentURL?.includes("app") ? "Switch to DEV" : "Switch to PROD"}
        </MenuItem>

        <MenuItem onClick={handleLogout}>
          <LogoutIcon sx={{ mr: 2 }} /> Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
