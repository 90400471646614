import React, { useEffect, useState } from "react";

import {
  Alert,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import Loader from "../../../components/loader/Loader";
import { hideConfirmModal } from "../../../redux/counterSlice";
import DriverOrderItem from "./DriverOrderItem";

function DriverPanel() {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(true);
  const [orderList, setOrderList] = useState([]);
  const [driver, setDriver] = useState(0);
  const [driverList, setDriverList] = useState();

  const loadInitialData = () => {
    const url = "/getAllDriverList";
    setLoader(true);
    axios
      .get(url)
      .then(function (response) {
        setDriverList(response?.data?.data);
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
        dispatch(hideConfirmModal({ msg: "" }));
      });
  };

  const listAllDriverOrders = () => {
    if (driver > 0) {
      setLoader(true);
      axios
        .post("/getAllOrdersForDriver", {
          driverId: driver,
          operation: "GET_DRIVER_ORDERS_OPERATION",
        })
        .then(function (response) {
          setOrderList(response?.data?.data);
        })
        .catch(function (err) {
          toast.error(err?.message);
        })
        .finally(() => {
          setLoader(false);
          dispatch(hideConfirmModal({ msg: "" }));
        });
    }
  };

  const handleShopChange = (value) => {
    setDriver(value);
    sessionStorage.setItem("selected_driver", value);
  };
  useEffect(() => {
    loadInitialData();
  }, []);

  useEffect(() => {
    listAllDriverOrders();
  }, [driver]);
  useEffect(() => {
    const selectedLocation = sessionStorage.getItem("selected_driver");
    setDriver(selectedLocation);
  }, [orderList]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <h4 className="text-dark">Driver Panel</h4>
          <Paper
            elevation={1}
            sx={{
              p: 3,
              margin: "auto",
              marginTop: "15px",
              flexGrow: 1,
            }}
          >
            <Grid
              container
              columnSpacing={{ xs: 4 }}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <FormControl margin="normal" className="w-100">
                  <InputLabel id="status_dropdown_label">Driver</InputLabel>
                  <Select
                    labelId="status_dropdown_label"
                    id={`status_dropdown_select`}
                    value={driver}
                    label="Update"
                    onChange={(e) => handleShopChange(e?.target?.value)}
                  >
                    <MenuItem value={0}>--Select Driver--</MenuItem>
                    {driverList?.map((item) => (
                      <MenuItem key={item?.driver_id} value={item?.driver_id}>
                        {`${item?.driver_name} -  (${item?.driver_mobile})`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                  spacing={3}
                >
                  {orderList?.length > 0 ? (
                    orderList?.map((driver) => {
                      return (
                        <Grid item xs={4}>
                          <DriverOrderItem driver={driver} />
                        </Grid>
                      );
                    })
                  ) : (
                    <Stack sx={{ width: "100%", mt: 3, p: 3 }} spacing={2}>
                      <Alert severity="warning">
                        <h6>No Data Found..</h6>
                      </Alert>
                    </Stack>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
    </>
  );
}

export default DriverPanel;
