import React from "react";

import { Box, Tab, Tabs, Typography } from "@mui/material";
import SectionOne from "./tab-section/SectionOne";
import SectionTwo from "./tab-section/SectionTwo";
import SectionThree from "./tab-section/SectionThree";
import SectionFour from "./tab-section/SectionFour";
import SectionFive from "./tab-section/SectionFive";
import { useNavigate, useParams } from "react-router-dom";
import Banner from "../Banner";
import PreviewBanner from "../PreviewBanner";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BottomBanner from "../BottomBanner";
import HeroSection from "./tab-section/HeroSection";

function HomeSection() {
  const { regionId, city } = useParams();
  const navigate = useNavigate();

  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  return (
    <>
      {/* <h5 className="text-dark font-weight-bold">Home Section</h5> */}

      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="d-flex ">
            <ArrowBackIcon
              className="text-danger"
              sx={{ marginRight: "1rem", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <h6 className="text-dark">Home Section / {city}</h6>
          </div>

          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab label="Hero" {...a11yProps(4)} />
            <Tab label="Banner" {...a11yProps(5)} />
            <Tab label="Preview Banner" {...a11yProps(6)} />
            <Tab label="Best Seller" {...a11yProps(0)} />
            <Tab label="New Arrivals" {...a11yProps(1)} />
            <Tab label="Flavour" {...a11yProps(2)} />
            <Tab label="Sub Categories" {...a11yProps(3)} />
            <Tab label="Custom" {...a11yProps(4)} />
            <Tab label="Bottom Banner" {...a11yProps(7)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          <HeroSection regionId={regionId} />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <Banner regionId={regionId} />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={2}>
          <PreviewBanner regionId={regionId} />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={3}>
          <SectionOne regionId={regionId} />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={4}>
          <SectionTwo regionId={regionId} />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={5}>
          <SectionThree regionId={regionId} />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={6}>
          <SectionFour regionId={regionId} />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={7}>
          <SectionFive regionId={regionId} />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={8}>
          <BottomBanner regionId={regionId} />
        </CustomTabPanel>
      </Box>
    </>
  );
}

export default HomeSection;
