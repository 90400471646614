import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  ButtonGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "../../../components/loader/Loader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OrderListTable from "./OrderListTable";
import OrderSummary from "./OrderSummary";
import {
  hideConfirmModal,
  removeNotificationItem,
  showConfirmModal,
  showModal,
} from "../../../redux/counterSlice";
import DispatchTable from "./DispatchTable";
import ShopDetailsTable from "./ShopDetailsTable";
import DriverDetailsTable from "./DriverDetailsTable";
import { DescriptionOutlined } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FailedAttemptsBtnGroup from "./FailedAttemptsBtnGroup";

function OrderDetails() {
  const { id, type, orderIds: failedOrderAttemptsId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { taxList, categoryList, unitList, flavourList, regionList } =
    useSelector((state) => state.counter);

  const [orderDetails, setOrderDetails] = useState(null);
  const [loader, setLoader] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");
  const [orderId, setOrderId] = useState(id);
  const [cakeDoneImages, setCakeDoneImages] = useState("");
  const [onlineTeamAccept, setOnlineTeamAccept] = useState("");
  const [locationDetailsId, setLocationDetailsId] = useState(0);
  const [driverId, setDriverId] = useState(0);
  const [shopId, setShopId] = useState(0);
  const [dispatchAcceptance, setDispatchAcceptance] = useState("");
  const [dispatchAcceptedAt, setDispatchAcceptedAt] = useState("");
  const [adminAcceptance, setAdminAcceptance] = useState("");
  const [orderStatusName, setOrderStatusName] = useState("");
  const [getDispatchOrderId, setGetDispatchOrderId] = useState([]);
  const [dispatchOrderStatus, setDispatchOrderStatus] = useState();
  const [dispatch_order_id, setDispatch_order_id] = useState();
  const [dispatchImagesList, setDispatchImagesList] = useState([]);
  const [getDriverOrderId, setGetDriverOrderId] = useState([]);
  const [getShopOrderId, setGetShopOrderId] = useState([]);
  const [shopList, setShopList] = useState([]);

  const [admin_approved_at, setAdmin_approved_at] = useState("");

  const [viewSchedule, setViewSchedule] = useState(false);
  const [scheduledDate, setScheduledDate] = useState("");
  const methods = useForm({
    resolver: yupResolver(),
  });

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const loadInitialData = () => {
    if (orderId > 0) {
      let URL = "";
      if (type == "failed") {
        URL = "/getOrderBackupDetails/" + orderId;
      } else {
        URL = "/getOrderDetails/" + orderId;
      }
      reset();
      setLoader(true);
      axios
        .get(URL)
        .then(function (response) {
          setOrderDetails((prev) => response?.data?.data);
          getAllLocationDetailsByRegionId(response?.data?.data?.region_id);
          getAllDriverList(response?.data?.data?.region?.city_id);
          getDispatchOrderByOrderId();
          getDriverOrderByOrderId();
          getShopOrderByOrderId();
          getAllShopDetailsByRegionId(response?.data?.data?.region_id);
          getAllLocationDetailsByRegionId(response?.data?.data?.region_id);
          setOrderStatus(response?.data?.data?.order_status);
          setOrderStatusName(response?.data?.data?.order_status);

          if (response?.data?.data?.dispatch_orders) {
            const dispatchOrders = response?.data?.data?.dispatch_orders;
            setCakeDoneImages(dispatchOrders.cake_done_image);
            setOnlineTeamAccept(dispatchOrders.online_team_accept);
            setLocationDetailsId(dispatchOrders.location_details_id);
            setDispatchAcceptance(dispatchOrders.dispatch_order_status);
            setDispatchAcceptedAt(dispatchOrders.dispatch_accepted_at);
            setAdminAcceptance(dispatchOrders.online_team_accept);
          }
        })
        .catch(function (err) {
          toast.error(err?.message);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  const getDriverOrderByOrderId = () => {
    setLoader(true);
    axios
      .get("/getDriverOrderByOrderId/" + orderId)
      .then(function (response) {
        setGetDriverOrderId((prev) => response?.data?.data);
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const getShopOrderByOrderId = () => {
    setLoader(true);
    axios
      .get("/getShopOrderByOrderId/" + orderId)
      .then(function (response) {
        setGetShopOrderId((prev) => response?.data?.data);
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getAllShopDetailsByRegionId = (id) => {
    setLoader(true);
    axios
      .get("/getAllShopDetailsByRegionId/" + id)
      .then(function (response) {
        setShopList((prev) => response?.data?.data);
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getAllLocationDetailsByRegionId = (regionId) => {
    setLoader(true);
    axios
      .get("/getAllLocationDetailsByRegionId/" + regionId)
      .then(function (response) {
        setLocationList((prev) => response?.data?.data);
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const getDispatchOrderByOrderId = () => {
    setLoader(true);
    axios
      .get("/getDispatchOrderByOrderId/" + orderId)
      .then(function (response) {
        if (response?.data?.data?.length > 0) {
          setGetDispatchOrderId((prev) => response?.data?.data);
          setDispatchOrderStatus(
            (prev) => response?.data?.data[0]?.dispatch_order_status
          );
          if (response?.data?.data?.length != 0) {
            setDispatch_order_id(
              (prev) => response?.data?.data[0]?.dispatch_order_id
            );
            getDispatchImagesById(response?.data?.data[0]?.dispatch_order_id);
          } else {
            getAllDriverList();
          }
        } else {
          getAllDriverList();
        }
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const getDispatchImagesById = (id) => {
    if (id == 0 || id == null) {
      return;
    }
    setLoader(true);
    axios
      .get("/getDispatchImagesById/" + id)
      .then(function (response) {
        setDispatchImagesList(response?.data?.data);
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const getAllDriverList = (id) => {
    if (id == 0 || id == null) {
      return;
    }
    setLoader(true);
    axios
      .get("/getAllDriverListByRegionId/" + id)
      .then(function (response) {
        setDriverList(response?.data?.data);
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    loadInitialData();
    dispatch(removeNotificationItem(id));
  }, [orderId]);
  const statusUpdateHandler = (value) => {
    setOrderStatusName(value);
  };

  const updateStatus = () => {
    if (orderStatus == "Confirmed") {
      if (orderStatusName == "ReadyToDelivered") {
        dispatch(
          showConfirmModal({
            msg: "'READY TO DELIVERY' without moving to 'DISPATCH'?",
            action: () => orderStatusUpdate(),
          })
        );
      } else {
        orderStatusUpdate();
      }
    } else {
      orderStatusUpdate();
    }
  };

  const orderStatusUpdate = () => {
    setLoader(true);
    const updateUrl = `/orderStatusUpdate/${orderDetails?.order_id}/${orderStatusName}`;
    axios
      .get(updateUrl)
      .then(function (response) {
        if (response?.data?.error === false) {
          loadInitialData();
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
        dispatch(hideConfirmModal({ msg: "" }));
      });
  };
  const onPushDispatch = async () => {
    if (locationDetailsId == null) {
      toast.error("Please select location");
      return;
    }
    try {
      setLoader(true);
      const param = {
        location_details_id: locationDetailsId,
        order_id: orderId,
        scheduled_date: scheduledDate,
      };
      const response = await axios.post("/createDispatchOrder", param);
      if (!response?.data?.error) {
        toast.success(response?.data?.message);
        loadInitialData();
        getDispatchOrderByOrderId();
      } else {
        let errDesc = "";
        if (response?.data?.data) {
          Object.values(response?.data?.data).map((error, index) => {
            errDesc =
              index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
          });
        } else {
          errDesc = response?.data?.message;
        }

        dispatch(
          showModal({
            msg: errDesc,
            error: response?.data?.error,
          })
        );
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      setLoader(false);
    }
  };
  const onPushDriver = async () => {
    if (orderDetails?.push_to_dispatch == 0) {
      if (locationDetailsId == null) {
        toast.error("Please select location");
        return;
      }
    }

    try {
      setLoader(true);
      const param = {
        location_details_id: locationDetailsId,
        order_id: orderId,
        driver_id: driverId,
        city_id: orderDetails?.region?.city_id,
        shop_id: getShopOrderId[0].shop_id,
      };
      const response = await axios.post("/createDriverOrder", param);
      if (!response?.data?.error) {
        toast.success(response?.data?.message);
        loadInitialData();
        getDispatchOrderByOrderId();
        getDriverOrderByOrderId();
      } else {
        let errDesc = "";
        if (response?.data?.data) {
          Object.values(response?.data?.data).map((error, index) => {
            errDesc =
              index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
          });
        } else {
          errDesc = response?.data?.message;
        }

        dispatch(
          showModal({
            msg: errDesc,
            error: response?.data?.error,
          })
        );
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      setLoader(false);
    }
  };
  const onPushToShop = async () => {
    if (shopId <= 0 || shopId == null) {
      toast.error("Please select shop");
      return;
    }
    try {
      setLoader(true);
      const param = {
        order_id: orderId,
        shop_id: shopId,
        region_id: orderDetails?.region_id,
      };
      const response = await axios.post("/createShopOrder", param);
      if (!response?.data?.error) {
        toast.success(response?.data?.message);
        loadInitialData();
        getDispatchOrderByOrderId();
        if (orderDetails?.delivery_mode == "Door Step Delivery") {
          getDriverOrderByOrderId();
        } else if (orderDetails?.delivery_mode == "Pick Up") {
          getShopOrderByOrderId();
        }
      } else {
        let errDesc = "";
        if (response?.data?.data) {
          Object.values(response?.data?.data).map((error, index) => {
            errDesc =
              index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
          });
        } else {
          errDesc = response?.data?.message;
        }

        dispatch(
          showModal({
            msg: errDesc,
            error: response?.data?.error,
          })
        );
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      setLoader(false);
    }
  };
  const downloadInvoiceHandler = async () => {
    try {
      setLoader(true);
      const response = await axios.get(
        "generate-pdf/" + orderDetails?.order_id,
        {
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], { type: "application/pdf" });

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = orderDetails?.invoice_no + ".pdf";
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading invoice PDF:", error);
    } finally {
      setLoader(false);
    }
  };
  const checkDispatchImageApproval = () => {
    const approvedImageArray = dispatchImagesList?.filter(
      (img) => img?.approve_status == 1
    );
    const approvedImageLength = approvedImageArray?.length;
    if (approvedImageLength < cakeDoneImages) {
      return true;
    }
    return false;
  };
  const failedOrderAttempts = (failedOrderIds) => {
    if (failedOrderIds) {
      return failedOrderIds.includes(",")
        ? [...new Set(failedOrderIds.split(","))]
        : [failedOrderIds];
    }
    return [];
  };
  return loader ? (
    <Loader />
  ) : (
    <div>
      <div className="d-flex ">
        <ArrowBackIcon
          className="text-danger"
          sx={{ marginRight: "1rem", cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        <h4 className="text-primary">Order Details #{orderId}</h4>
      </div>
      {orderDetails && (
        <>
          <Paper
            elevation={1}
            sx={{
              p: 3,
              margin: "auto",
              marginTop: "15px",
              flexGrow: 1,
            }}
          >
            <Grid
              container
              columnSpacing={{ xs: 4 }}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item xs={4}>
                <h6 className="text-primary ">
                  Location : &nbsp;
                  <span className="location-text text-danger">
                    {orderDetails?.region.city?.name}
                  </span>
                </h6>
              </Grid>

              {type == "failed" ? (
                <Grid
                  item
                  xs={5}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  <FailedAttemptsBtnGroup
                    value={orderId}
                    options={failedOrderAttempts(failedOrderAttemptsId)}
                    setOrderId={setOrderId}
                  />
                  {/* <FormControl
                    fullWidth
                    error={errors?.["order_status"]?.message ? true : false}
                    margin="normal"
                  >
                    <InputLabel id="status_dropdown_label">
                      Failed Attempts
                    </InputLabel>
                    <Select
                      labelId="status_dropdown_label"
                      id={`status_dropdown_select`}
                      value={orderId}
                      label="Update"
                      onChange={(e) => setOrderId(e?.target?.value)}
                    >
                      {failedOrderAttempts(failedOrderAttemptsId)?.map(
                        (item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl> */}
                </Grid>
              ) : orderStatus != "Delivered" ? (
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  <FormControl
                    fullWidth
                    error={errors?.["order_status"]?.message ? true : false}
                    margin="normal"
                  >
                    <InputLabel id="status_dropdown_label">Status</InputLabel>
                    <Select
                      labelId="status_dropdown_label"
                      id={`status_dropdown_select`}
                      value={orderStatusName}
                      label="Update"
                      onChange={(e) => statusUpdateHandler(e?.target?.value)}
                    >
                      <MenuItem value={"Submitted"} disabled={true}>
                        Submitted
                      </MenuItem>
                      <MenuItem
                        value={"Confirmed"}
                        disabled={
                          orderStatus == "Confirmed" ||
                          orderStatus == "Dispatched" ||
                          orderStatus == "ReadyToDelivered"
                        }
                      >
                        Confirmed
                      </MenuItem>
                      <MenuItem
                        value={"Dispatched"}
                        disabled={
                          orderStatus == "Submitted" ||
                          orderStatus == "Dispatched" ||
                          orderStatus == "ReadyToDelivered"
                        }
                      >
                        Dispatched
                      </MenuItem>
                      <MenuItem
                        value={"ReadyToDelivered"}
                        disabled={
                          orderStatus == "Submitted" ||
                          orderStatus == "ReadyToDelivered" ||
                          (orderStatus == "Dispatched" &&
                            onlineTeamAccept == 0) ||
                          (orderStatus == "Dispatched" &&
                            checkDispatchImageApproval())
                        }
                      >
                        ReadyToDelivered
                      </MenuItem>
                      <MenuItem
                        value={"Delivered"}
                        disabled={
                          orderStatus == "Submitted" ||
                          orderStatus == "Confirmed" ||
                          orderStatus == "Dispatched" ||
                          !(
                            getShopOrderId[0]?.picked_up_at ||
                            getDriverOrderId[0]?.delivered_at
                          )
                        }
                      >
                        Delivered
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    onClick={() => updateStatus()}
                    disabled={
                      orderStatus == "Cancelled" || orderDetails?.hold == 1
                    }
                  >
                    Update
                  </Button>
                </Grid>
              ) : (
                <Button
                  variant="contained"
                  onClick={downloadInvoiceHandler}
                  size="small"
                  color="secondary"
                  startIcon={<DescriptionOutlined />}
                >
                  Invoice
                </Button>
              )}
              <Grid item xs={12}>
                <OrderListTable
                  productList={orderDetails?.ordered_products}
                  addonList={orderDetails?.ordered_addons}
                  priceDetails={orderDetails?.order_price_details}
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper
            elevation={1}
            sx={{
              p: 3,
              margin: "auto",
              marginTop: "15px",
              flexGrow: 1,
            }}
          >
            <Grid
              container
              columnSpacing={{ xs: 4 }}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {orderDetails?.push_to_dispatch == 0 &&
                orderStatus == "Dispatched" && (
                  <Grid
                    item
                    xs={12}
                    className="my-2"
                    style={{
                      display: orderStatus === "Submitted" ? "none" : "block",
                    }}
                  >
                    <Typography variant="h6">
                      Push To Processing Team
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={5}>
                        <FormControl fullWidth>
                          <Select
                            value={locationDetailsId}
                            onChange={(e) =>
                              setLocationDetailsId(e?.target?.value)
                            }
                          >
                            <MenuItem value={0}>
                              Select Processing Location
                            </MenuItem>
                            {locationList?.map((country) => (
                              <MenuItem
                                key={country?.location_details_id}
                                value={country?.location_details_id}
                              >
                                {country?.location_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={viewSchedule}
                              onChange={() => {}}
                              color="primary"
                            />
                          }
                          label="Schedule Later"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        {viewSchedule && (
                          <TextField
                            type="datetime-local"
                            value={scheduledDate}
                            onChange={(e) => setScheduledDate(e?.target?.value)}
                            fullWidth
                          />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => onPushDispatch()}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

              {orderStatus == "ReadyToDelivered" &&
                getDriverOrderId?.length == 0 &&
                getShopOrderId?.length != 0 &&
                orderDetails?.delivery_mode == "Door Step Delivery" &&
                (getShopOrderId?.[0].accepted_at != null
                  ? getShopOrderId?.[0].accepted_at
                  : null) && (
                  <Grid item xs={12} className="my-2">
                    <Typography variant="h6">Push To Driver Team</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={5}>
                        <FormControl fullWidth>
                          <Select
                            value={driverId}
                            onChange={(e) => setDriverId(e?.target?.value)}
                          >
                            <MenuItem value={0}>Select Driver</MenuItem>
                            {driverList?.map((country) => (
                              <MenuItem
                                key={country.driver_id}
                                value={country.driver_id}
                              >
                                {`${country?.driver_name} -  (${country?.driver_mobile})`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => onPushDriver()}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              {orderStatus == "ReadyToDelivered" &&
                getShopOrderId?.length == 0 &&
                getDriverOrderId?.length == 0 && (
                  <Grid item xs={12} className="my-2">
                    <Typography variant="h6">Push To Shop</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={5}>
                        <FormControl fullWidth>
                          <Select
                            value={shopId}
                            onChange={(e) => setShopId(e?.target?.value)}
                          >
                            <MenuItem value={0}>Select Shop</MenuItem>
                            {shopList?.map((shop) => (
                              <MenuItem
                                key={shop?.shop_details_id}
                                value={shop?.shop_details_id}
                              >
                                {`${shop?.shop_name}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => onPushToShop()}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              {orderDetails?.push_to_dispatch === 1 &&
                getDispatchOrderId?.length !== 0 && (
                  <Grid item xs={12}>
                    <DispatchTable
                      pushToDispatch={orderDetails?.push_to_dispatch}
                      getDispatchOrderId={getDispatchOrderId}
                      dispatchImagesList={dispatchImagesList}
                      setLoader={setLoader}
                      getOrderDetails={loadInitialData}
                      getDispatchImagesById={getDispatchImagesById}
                      locationDetailsId
                    />
                  </Grid>
                )}
              {getShopOrderId?.length > 0 && (
                <Grid item xs={12}>
                  <ShopDetailsTable getShopOrderId={getShopOrderId} />
                </Grid>
              )}
              {getDriverOrderId?.length > 0 && (
                <Grid item xs={12}>
                  <DriverDetailsTable getDriverOrderId={getDriverOrderId} />
                </Grid>
              )}

              <Grid item xs={12}>
                <h5 className="text-primary mt-3">Order Summary</h5>
                <OrderSummary orderDetails={orderDetails} />
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
    </div>
  );
}

export default OrderDetails;
