import "./App.css";
// import Login from "./screens/login/login";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-photo-view/dist/react-photo-view.css";
import axios from "axios";
import LoginScreen from "./screens/login/login";
import toast, { Toaster } from "react-hot-toast";
import DashboardIndex from "./screens/dashboard/DashboardIndex";
import AlertDialog from "./components/alert/AlertDialog";
import Banner from "./screens/home/Banner";
import { Box, styled } from "@mui/material";
import SideNav from "./layout/sideNav/sideNav";
import { useDispatch, useSelector } from "react-redux";
import Category from "./screens/products/category/Category";
import SubCategory from "./screens/products/sub category/SubCategory";
import Products from "./screens/products/products/Products";
import ProductForm from "./screens/products/products/ProductForm";
import Addons from "./screens/products/addons/Addons";
import Flavour from "./screens/products/flavour/Flavour";
import PreviewBanner from "./screens/home/PreviewBanner";
import ProductsImage from "./screens/products/products/productImage/ProductsImage";
import ConfirmDialog from "./components/alert/ConfirmDialog";
import HomeSection from "./screens/home/home-section/HomeSection";
import OrderList from "./screens/order/orderList/OrderList";
import OrderDetails from "./screens/order/orderList/OrderDetails";
import DispatchPanel from "./screens/external/dispatch/DispatchPanel";
import ViewDispatchOrder from "./screens/external/dispatch/ViewDispatchOrder";
import ShopPanel from "./screens/external/shop/ShopPanel";
import ViewShopOrder from "./screens/external/shop/ViewShopOrder";
import DriverPanel from "./screens/external/driver/DriverPanel";
import ViewDriverOrder from "./screens/external/driver/ViewDriverOrder";
import Discount from "./screens/discount/discount";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CategoryDiscount from "./screens/discount/category-discount";
import ProductDiscount from "./screens/discount/product-discount";
import Promo from "./screens/discount/promo";
import Pusher from "pusher-js";
import { useEffect } from "react";
// import notificationSound from "./assets/audio/buzzer.mp3"; // Assuming you have a notification sound file
import notificationSound from "./assets/audio/new_order_received.mp3"; // Assuming you have a notification sound file
import { addNotificationList } from "./redux/counterSlice";
import HomeRegionSelection from "./screens/home/home-section/HomeRegionSelection";
import City from "./screens/region/city/City";
import Location from "./screens/region/location/Location";
import Shop from "./screens/region/shop/Shop";
import useSessionStorage from "./hooks/useSessionStorage";
import ActiveLastBreadcrumb from "./components/breadcrumbs/BreadCrumbs";

function App() {
  const [baseURL, setBaseURL] = useSessionStorage(
    "baseURL",
    "https://app.blaackforestcakes.com/api/"
  );

  axios.defaults.baseURL = baseURL;
  // axios.defaults.baseURL = "http://localhost:8001/api/";

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.counter?.userData);

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));

  const audio = new Audio(notificationSound);
  audio.load();
  useEffect(() => {
    // Capture user interaction
    let soundEnabled = false;
    const enableSound = () => {
      audio
        .play()
        .then(() => {
          // Pause immediately to avoid playing now, we just wanted to allow future play
          audio.pause();
          soundEnabled = true;
        })
        .catch((error) => {
          console.error("User interaction required to play audio:", error);
        });
    };

    window.addEventListener("click", enableSound, { once: true });
    window.addEventListener("touchstart", enableSound, { once: true });

    var pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: "ap2",
    });

    var channel = pusher.subscribe("blaack-forest-app-channel");
    channel.bind("admin-event", function (data) {
      dispatch(addNotificationList(data));

      if (soundEnabled) {
        audio.play().catch((error) => {
          toast.error("Failed to play audio:", error);
          console.error("Failed to play audio:", error);
          alert("Failed to play audio:", error);
        });
      } else {
        toast.error("Audio playback is blocked until user interaction.");
        alert("Audio playback is blocked until user interaction.");
      }
    });

    return () => {
      pusher.unsubscribe("blaack-forest-app-channel");
    };
  }, [dispatch, notificationSound]);

  const ApplicationRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={<Navigate replace to="/login" />} />
        <Route
          exact
          path="/login"
          element={
            userData?.loggedIn ? <Navigate to="/dashboard" /> : <LoginScreen />
          }
        ></Route>

        <Route exact path="/dashboard" element={<DashboardIndex />}></Route>
        {/* BF ADMIN ROUTES */}
        <Route exact path="/banner" element={<Banner />} />
        <Route exact path="/general-banner" element={<PreviewBanner />} />
        <Route path="/home-region" element={<HomeRegionSelection />} />
        <Route path="/home-section/:regionId/:city" element={<HomeSection />} />

        <Route path="/category" element={<Category />} />
        <Route path="/sub-category" element={<SubCategory />} />
        <Route replace path="/products" element={<Products />} />
        <Route path="/products/new-product" element={<ProductForm />} />
        <Route path="/products/edit-product/:id" element={<ProductForm />} />
        <Route
          path="/products/edit-product-image/:id"
          element={<ProductsImage />}
        />
        <Route path="/addons" element={<Addons />} />
        <Route path="/flavour" element={<Flavour />} />
        <Route path="/order-list" element={<OrderList type="ongoing" />} />
        <Route
          path="/delivered-order-list"
          element={<OrderList type="delivered" />}
        />
        <Route
          path="/cancelled-order-list"
          element={<OrderList type="cancelled" />}
        />
        <Route
          path="/failed-order-list"
          element={<OrderList type="failed" />}
        />
        {/* <Route path="/order-list/:id" element={<OrderDetails />} /> */}
        <Route
          path="/order-list/:id/:type?/:orderIds?"
          element={<OrderDetails />}
        />

        <Route path="/dispatch-panel" element={<DispatchPanel />} />
        <Route
          path="/view-dispatch-order/:id"
          element={<ViewDispatchOrder />}
        />
        <Route path="/shop-panel" element={<ShopPanel />} />
        <Route path="/view-shop-order/:id" element={<ViewShopOrder />} />
        <Route path="/driver-panel" element={<DriverPanel />} />
        <Route
          path="/view-driver-order/:driverId/:orderId"
          element={<ViewDriverOrder />}
        />
        <Route path="/discount" element={<Discount />} />
        <Route path="/category-discount" element={<CategoryDiscount />} />
        <Route path="/product-discount" element={<ProductDiscount />} />
        <Route path="/promo" element={<Promo />} />
        <Route path="/city" element={<City />} />
        <Route path="/location" element={<Location />} />
        <Route path="/shop" element={<Shop />} />
      </Routes>
    );
  };
  return (
    <Router>
      <Toaster
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <AlertDialog />
      <ConfirmDialog />
      {userData?.loggedIn ? (
        <>
          <Box sx={{ display: "flex" }}>
            <SideNav />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <DrawerHeader />
              {/* <ActiveLastBreadcrumb /> */}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ApplicationRoutes />
              </LocalizationProvider>
            </Box>
          </Box>
        </>
      ) : (
        <ApplicationRoutes />
      )}
    </Router>
  );
}

export default App;
