import * as yup from "yup";

export const courseSchema = yup.object().shape({
  applying_for: yup.string().required("required"),
});
export const saveCourseSchema = yup.object().shape({
  college: yup.string().required("required"),
  course_type: yup.string().required("required"),
  courseName: yup.string().required("required"),
  fees: yup.string().required("required"),
});
export const paymentSchema = yup.object().shape({
  txn_no: yup.string().required("required"),
  amount_paid: yup.string().required("required"),
  payment_date: yup
    .string()
    .required("Transaction date is required")
    .test("len", "Invalid date format", (val) => val?.length === 10),

  remarks: yup.string().required("required"),
  payment_type: yup.string().required("required"),
});

export const personalDetailSchema = yup.object().shape({
  name: yup.string().required("required"),
  aadhar_no: yup
    .string()
    .min(12, "Only 12 digits allowed")
    .max(12, "Only 12 digits allowed")
    .required("Aadhar is required"),
  place_of_birth: yup.string().required("required"),
  // dob: yup.string().required("required"),
  dob: yup
    .string()
    .required("Date of birth is required")
    .test("len", "Invalid date format", (val) => val?.length === 10),

  gender: yup.string().required("required"),
  mother_tongue: yup.string().required("required"),
  email: yup.string().required("required").email(),
  mobile: yup.string().required("required").max(10),
  mobile_whatsapp: yup.string().max(10).required("required"),
  communication_address_line_1: yup.string().required("required"),
  communication_address_line_2: yup.string().required("required"),
  communication_address_line_3: yup.string(),
  communication_city: yup.string().required("required"),
  communication_district: yup.string().required("required"),
  communication_state: yup.string().required("required"),
  communication_pincode: yup.string().required("required").max(6),
  communication_country: yup.string().required("required"),
  permanent_address_line_1: yup.string().required("required"),
  permanent_address_line_2: yup.string().required("required"),
  permanent_address_line_3: yup.string(),
  permanent_city: yup.string().required("required"),
  permanent_district: yup.string().required("required"),
  permanent_state: yup.string().required("required"),
  permanent_pincode: yup.string().required("required").max(6),
  permanent_country: yup.string().required("required"),
  community: yup.string().required("required"),
  caste: yup.string().required("required"),
  nationality: yup.string().required("required"),
  parent_father_type: yup.string().required("required"),
  father_name: yup.string().required("required"),
  father_mobile: yup.string().required("required"),
  father_occupation: yup.string().required("required"),
  father_annual_income: yup.string().required("required"),
  parent_mother_type: yup.string().required("required"),
  mother_name: yup.string().required("required"),
  mother_mobile: yup.string().required("required"),
});

export const UGSchemaWithOutDisability = yup.object().shape({
  twelvelth_reg_mark: yup.string().required("required"),
  name_location: yup.string().required("required"),
  medium: yup.string().required("required"),
  exam: yup.string().required("required"),
  board: yup.string().required("required"),
  month_year: yup
    .string()
    .required("required")
    .test(
      "len",
      "Invalid date format",
      (val) => val?.split("-")[0].length == 4
    ),
  first_attempt: yup.string().required("required"),
  laptop_received: yup.string().required("required"),
  orphan: yup.string().required("required"),
  first_graduate: yup.string().required("required"),
  differently_abled: yup.string().required("required"),
  son_of_exservicemen: yup.string().required("required"),
  tamil_origin_from_andaman: yup.string().required("required"),
  distinction_sports: yup.string().required("required"),
});
export const UGSchemaWithDisability = yup.object().shape({
  twelvelth_reg_mark: yup.string().required("required"),
  name_location: yup.string().required("required"),
  medium: yup.string().required("required"),
  exam: yup.string().required("required"),
  board: yup.string().required("required"),
  month_year: yup
    .string()
    .required("required")
    .test(
      "len",
      "Invalid date format",
      (val) => val?.split("-")[0].length == 4
    ),
  first_attempt: yup.string().required("required"),
  laptop_received: yup.string().required("required"),
  orphan: yup.string().required("required"),
  first_graduate: yup.string().required("required"),
  differently_abled: yup.string().required("required"),
  type_of_differently_abled: yup.string().required("required"),
  son_of_exservicemen: yup.string().required("required"),
  tamil_origin_from_andaman: yup.string().required("required"),
  distinction_sports: yup.string().required("required"),
});

export const PGSchemaWithOutDisability = yup.object().shape({
  college_name: yup.string().required("required"),
  university: yup.string().required("required"),
  reg_no: yup.string().required("required"),
  degree: yup.string().required("required"),
  pattern: yup.string().required("required"),
  month_year: yup
    .string()
    .required("required")
    .test(
      "len",
      "Invalid date format",
      (val) => val?.split("-")[0].length == 4
    ),
  date_of_admission: yup.string().required("required"),
  differently_abled: yup.string().required("required"),
  son_of_exservicemen: yup.string().required("required"),
  tamil_origin_from_andaman: yup.string().required("required"),
  distinction_sports: yup.string().required("required"),
});
export const PGSchemaWithDisability = yup.object().shape({
  college_name: yup.string().required("required"),
  university: yup.string().required("required"),
  reg_no: yup.string().required("required"),
  degree: yup.string().required("required"),
  pattern: yup.string().required("required"),
  month_year: yup
    .string()
    .required("required")
    .test(
      "len",
      "Invalid date format",
      (val) => val?.split("-")[0].length == 4
    ),
  date_of_admission: yup.string().required("required"),
  differently_abled: yup.string().required("required"),
  type_of_differently_abled: yup.string().required("required"),
  son_of_exservicemen: yup.string().required("required"),
  tamil_origin_from_andaman: yup.string().required("required"),
  distinction_sports: yup.string().required("required"),
});

export const basicInformation = yup.object().shape({
  event_title: yup.string().required("required"),
  event_slug: yup.string().required("required"),
  categories: yup.string().required("required"),
  description: yup.string().required("required"),
  start_date: yup
    .string()
    .required("Start Date is required")
    .test("len", "Invalid date format", (val) => val?.length === 16),
});

export const virtualLocation = yup.object().shape({
  event_mode: yup.string().required("required"),
  web_address: yup.string().required("required"),
});
export const onGroundLocation = yup.object().shape({
  event_mode: yup.string().required("required"),
  venue_name: yup.string().required("required"),
  street: yup.string().required("required"),
  map_iframe: yup.string().required("required"),
  city: yup.string().required("required"),
});

export const ticketSchema = yup.object().shape({
  ticket_type: yup.string().required("required"),
  ticket_name: yup.string().required("required"),
  ticket_description: yup.string().required("required"),
  quantity: yup
    .number()
    .required("required")
    .integer()
    .min(
      yup.ref("minimum_booking"),
      "Quantity should be greater than or equal to minimum booking"
    )
    .max(
      yup.ref("maximum_booking"),
      "Quantity should be less than or equal to maximum booking"
    ),
  minimum_booking: yup.number().required("required").integer(),
  maximum_booking: yup.number().required("required").integer(),
});
export const finalConfirmationSchema = yup.object().shape({
  applying_for: yup.string().required("required"),
});

// BF ADMIN SCHEMA's
export const categorySchema = yup.object().shape({
  category_name: yup.string().required("required"),
  category_description: yup.string().required("required"),
});

export const subCategorySchema = yup.object().shape({
  category_id: yup.string().required("required"),
  sub_category_name: yup.string().required("required"),
  sub_category_description: yup.string().required("required"),
  tax_id: yup.string().required("required"),
  hsn: yup.string().required("required"),
});
export const flavourSchema = yup.object().shape({
  flavour_name: yup.string().required("required"),
});
export const lastHomeSectionSchema = yup.object().shape({
  name: yup.string().required("required"),
  type: yup.string().required("required"),
  ref_id: yup.string().required("required"),
});
export const addonSchema = yup.object().shape({
  region_id: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
    )
    .min(1, "Region is required"),
  product_name: yup.string().required("required"),
  price: yup.string().required("required"),
  tax_id: yup.string().required("required"),
  hsn: yup.string().required("required"),
});

const variationSchema = yup.object().shape({
  weight: yup.number().required(),
  egg: yup.string().required(),
  eggLess: yup.string().required(),
  eggPreparation: yup.string().required(),
  // egglessPreparation: yup.string().required(),
  sku: yup.string().required(),
});
export const citySchema = yup.object().shape({
  state_id: yup.number().required(),
  city_id: yup.number().required(),
});
export const locationSchema = yup.object().shape({
  region_id: yup.number().required(),
  location_code: yup.string().required(),
  location_name: yup.string().required(),
  address: yup.string().required(),
  mobile_no: yup.string().required(),
  pincode: yup.string().required(),
});
export const shopSchema = yup.object().shape({
  region_id: yup.number().required(),
  shop_code: yup.string().required(),
  shop_name: yup.string().required(),
  address: yup.string().required(),
  mobile_no: yup.string().required(),
  pincode: yup.string().required(),
});

export const productSchema = yup.object().shape({
  category_id: yup.number().required(),
  sub_category_id: yup.number().required(),
  product_name: yup.string().required(),
  product_description: yup.string().required(),
  hsn: yup.string().required(),
  tax_id: yup.number().required(),
  preparation: yup.string().nullable(),
  new_product: yup.number().required(),
  best_selling: yup.number().required(),
  unit_id: yup.number().required(),
  short_description: yup.string().required(),
  product_price: yup.string().required(),
  base_eggless_price: yup.string().required(),
  // COD_egg: yup.number().required(),
  // COD_eggless: yup.number().required(),
  region: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
    )
    .min(1, "Region is required"),
  flavour: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
    )
    .min(1, "Flavour is required"),
  variation: yup
    .array()
    .of(variationSchema)
    .min(1, "At least one variation is required"),
});
export const sectionOneSchema = yup.object().shape({
  heading: yup.string().required("required"),
});
export const discountSchema = yup.object().shape({
  discount_name: yup.string().required("required"),
  discount_percentage: yup.string().required("required"),
  fromDate: yup.string().required("required"),
  toDate: yup.string().required("required"),
});

export const categoryDiscountSchema = yup.object().shape({
  category: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
    )
    .min(1, "Category is required"),
  discount_id: yup.string().required("required"),
});

export const promoSchema = yup.object().shape({
  promo_code: yup.string().required("required"),
  min_value: yup.string().required("required"),
  type: yup.string().required("required"),
  discount: yup.string().required("required"),
  terms_conditions: yup.string().required("required"),
});
export const migrateOrderSchema = yup.object().shape({
  razorpay_payment_id: yup.string().required("required"),
  password: yup.string().required("required"),
});
