import React from "react";
import {
  TableContainer,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Table,
  Grid,
  Button,
  Chip,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Box,
  colors,
  CircularProgress,
} from "@mui/material";
import { MyLocationOutlined } from "@mui/icons-material";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import axios from "axios";
import toast from "react-hot-toast";
import { VisibilityOutlined } from "@mui/icons-material";
import { FormInputText } from "../../../components/form-components/FormInputText";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { migrateOrderSchema } from "../../../app/schema";
import { useNavigate } from "react-router-dom";
function OrderSummary({ orderDetails }) {
  const navigate = useNavigate();
  const [openMessage, setOpenMessage] = React.useState(false);
  const [openMigrate, setOpenMigrate] = React.useState(false);
  const [msgString, setMsgString] = React.useState("");
  const [orderIdResponse, setOrderIdResponse] = React.useState(null);
  const [loader, setLoader] = React.useState(false);

  const openCommentPopUp = (message) => {
    setMsgString(message);
    setOpenMessage(true);
  };

  const handleClose = () => {
    setOpenMessage(false);
    setMsgString("");
  };
  const loadRazorpayOrderDetails = (razorpay_order_id) => {
    if (razorpay_order_id) {
      setLoader(true);
      axios
        .get("get-razorpay-order/" + razorpay_order_id)
        .then(function (response) {
          !response?.data?.error && setOrderIdResponse(response?.data?.data);
        })
        .catch(function (err) {
          toast.error(err?.message);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };
  const RazorPayOrderDetailsDialog = () => {
    const orderData = orderIdResponse;
    if (orderData == null) {
      return null;
    }

    return (
      <Dialog
        // open={!!!orderData}
        open={true}
        onClose={() => setOrderIdResponse(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Razorpay Order Details
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="order-details-container">
              <div className="order-detail-item">
                <p>
                  <span>ID:</span> {orderData?.id}
                </p>
              </div>
              <div className="order-detail-item">
                <p>
                  <span>Entity:</span> {orderData?.entity}
                </p>
              </div>
              <div className="order-detail-item">
                <p>
                  <span>Amount:</span> &#8377; {orderData?.amount / 100}
                </p>
              </div>
              <div className="order-detail-item">
                <p>
                  <span>Amount Paid:</span> &#8377;{" "}
                  {orderData?.amount_paid / 100}
                </p>
              </div>
              <div className="order-detail-item">
                <p>
                  <span>Amount Due:</span> &#8377; {orderData?.amount_due / 100}
                </p>
              </div>
              <div className="order-detail-item">
                <p>
                  <span>Currency:</span> {orderData?.currency}
                </p>
              </div>
              <div className="order-detail-item">
                <p>
                  <span>Receipt:</span> {orderData?.receipt || "N/A"}
                </p>
              </div>
              <div className="order-detail-item">
                <p>
                  <span>Offer ID:</span> {orderData?.offer_id}
                </p>
              </div>
              <div className="order-detail-item">
                <p>
                  <span>Offers:</span> {orderData?.offers?.join(", ")}
                </p>
              </div>
              <div className="order-detail-item">
                <p>
                  <span>Status:</span> {orderData?.status}
                </p>
              </div>
              <div className="order-detail-item">
                <p>
                  <span>Attempts:</span> {orderData?.attempts}
                </p>
              </div>
              <div className="order-detail-item">
                <p>
                  <span>Notes:</span>{" "}
                  {orderData?.notes?.length > 0
                    ? orderData?.notes?.join(", ")
                    : "None"}
                </p>
              </div>
              <div className="order-detail-item">
                <p>
                  <span>Created At:</span>{" "}
                  {new Date(orderData?.created_at * 1000).toLocaleString()}
                </p>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOrderIdResponse(null)} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const MigrateOrderDialog = () => {
    const methods = useForm({
      resolver: yupResolver(migrateOrderSchema),
    });
    const {
      handleSubmit,
      control,
      setValue,
      reset,
      formState: { errors },
    } = methods;
    const onSubmit = async (data) => {
      try {
        setLoader(true);
        let response;

        response = await axios.post("/migrateOrder", {
          ...data,
          order_id: orderDetails?.order_id,
        });

        if (!response?.data?.error) {
          toast.success(response?.data?.message);
          navigate(-1);
          reset();
        } else {
          toast.error(response?.data?.message);
        }
      } catch (error) {
        toast.error(error?.message);
      } finally {
        setLoader(false);
        setOpenMigrate(false);
      }
    };
    return (
      <Dialog
        open={openMigrate}
        onClose={() => setOpenMigrate(false)}
        maxWidth={"md"}
      >
        <DialogTitle className="text-primary">Migrate Order</DialogTitle>
        <DialogContent>
          <Grid container columnSpacing={{ xs: 5 }}>
            <Grid item xs={12}>
              <FormInputText
                name="razorpay_payment_id"
                control={control}
                label="Razorpay Payment ID"
                errors={errors}
                mandatory="true"
                defaultValue={""}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name="password"
                control={control}
                label="Password"
                errors={errors}
                mandatory="true"
                defaultValue={""}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenMigrate(false)}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const MessageDialog = () => {
    return (
      <Dialog
        open={openMessage}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Message</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
              {msgString}
            </pre>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const DoorStepShippingAddressDetails = () => {
    return (
      <div className="card bg-light p-3">
        <Table className="w-100 table table-sm table-borderless text-center table-hover table-light">
          <TableHead>
            <TableRow className="thead-light">
              <TableCell colSpan={2}>
                <h5>Shipping Address</h5>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="order-summary">
            <TableRow>
              <TableCell>Contact Name:</TableCell>
              <TableCell>{orderDetails?.contact_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Contact No:</TableCell>
              <TableCell>{orderDetails?.contact_mobile}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Address:</TableCell>
              <TableCell>
                {orderDetails?.ordered_address?.full_address}
                <br />
                {orderDetails?.ordered_address?.address_city_district} -{" "}
                {orderDetails?.ordered_address?.address_pincode}
                <br />
                {orderDetails?.ordered_address?.address_state}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Map:</TableCell>
              <TableCell>
                <MyLocationOutlined
                  sx={{ color: "red", fontSize: "18px", mr: 1 }}
                />
                <Link
                  target="_blank"
                  rel="noopener"
                  color="primary"
                  href={`https://maps.google.com/?q=${orderDetails?.ordered_address?.latitude},${orderDetails?.ordered_address?.longitude}`}
                >
                  Locate on map
                </Link>
              </TableCell>
            </TableRow>
            {orderDetails?.hold === 1 && (
              <>
                <TableRow>
                  <TableCell>Hold Reason:</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        openCommentPopUp(orderDetails?.hold_reason)
                      }
                      style={{ height: "20px" }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Hold By:</TableCell>
                  <TableCell>{orderDetails?.hold_by}</TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </div>
    );
  };

  const PickUpShippingDetails = () => {
    return (
      <TableContainer>
        <div className="card bg-light p-3">
          <Table className="w-100 table table-sm table-borderless text-center table-hover table-light">
            <TableHead>
              <TableRow className="thead-light">
                <TableCell colSpan={2}>
                  <h5>Pick Up Location</h5>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="order-summary">
              <TableRow>
                <TableCell>Contact Name:</TableCell>
                <TableCell>{orderDetails?.contact_name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Contact No:</TableCell>
                <TableCell>{orderDetails?.contact_mobile}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Address:</TableCell>
                <TableCell>
                  Blaack Forest <br />
                  {orderDetails?.shops?.shop_name}
                  <br />
                  {orderDetails?.shops?.address}
                  <br />
                  Pincode: {orderDetails?.shops?.pincode}
                  <br />
                  {orderDetails?.region?.city?.name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Store Contact No:</TableCell>
                <TableCell>{orderDetails?.shops.mobile_no}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </TableContainer>
    );
  };
  const PaymentDetails = () => {
    return (
      <div className="card bg-light p-3">
        <Table className="w-100 table table-sm table-borderless text-center table-hover table-light">
          <TableHead>
            <TableRow className="thead-light">
              <TableCell colSpan={2}>
                <h5>Payment Details</h5>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="order-summary">
            <TableRow>
              <TableCell>Payment Mode:</TableCell>
              <TableCell>{orderDetails?.payment_mode}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Order ID:</TableCell>
              <TableCell>
                {orderDetails?.order_tracking_id}
                <Box
                  component="span"
                  sx={{ display: "inline-flex", cursor: "pointer", ml: 1 }}
                  onClick={() =>
                    loadRazorpayOrderDetails(orderDetails?.order_tracking_id)
                  }
                >
                  {loader ? (
                    <CircularProgress size="16px" />
                  ) : (
                    <VisibilityOutlined
                      sx={{
                        fontSize: "18px",
                        color: "blue",
                        position: "relative",
                        top: 4,
                      }}
                    />
                  )}
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Razor Pay ID:</TableCell>
              <TableCell>{orderDetails?.razorpay_payment_id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Payment:</TableCell>
              <TableCell>
                <Chip
                  size="small"
                  label={orderDetails?.paid == 1 ? "DONE" : "PENDING"}
                  color={orderDetails?.paid == 1 ? "success" : "error"}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Paid At:</TableCell>
              <TableCell>{orderDetails?.paid_at}</TableCell>
            </TableRow>
            {orderDetails?.business_name && (
              <TableRow>
                <TableCell>Business Name:</TableCell>
                <TableCell>{orderDetails?.business_name}</TableCell>
              </TableRow>
            )}
            {orderDetails?.gstin && (
              <TableRow>
                <TableCell>GSTIN :</TableCell>
                <TableCell>{orderDetails?.gstin}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {orderDetails?.paid != 1 && (
          <Button
            variant="contained"
            onClick={() => setOpenMigrate(true)}
            size="small"
          >
            Migrate Order
          </Button>
        )}
      </div>
    );
  };

  const CustomerDetails = () => {
    return (
      <div className="card bg-light p-3">
        <Table className="w-100 table table-sm table-borderless text-center table-hover table-light">
          <TableHead>
            <TableRow className="thead">
              <TableCell colSpan={2}>
                <h5>Customer Details</h5>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="order-summary">
            <TableRow>
              <TableCell>Name:</TableCell>
              <TableCell>
                <b>{orderDetails?.customer_details?.customer_name}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Contact No:</TableCell>
              <TableCell>
                <b>{orderDetails?.customer_details?.customer_mobile}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email:</TableCell>
              <TableCell>{orderDetails?.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Order Contact No:</TableCell>
              <TableCell>{orderDetails?.contact_mobile}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  };

  const Summary = () => {
    return (
      <div className="card bg-light p-3">
        <Table className="w-100 table table-sm table-borderless text-center table-light table-hover">
          <TableHead>
            <TableRow className="thead-light">
              <TableCell colSpan={2}>
                <h5>Summary</h5>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="order-summary">
            <TableRow>
              <TableCell>Order ID:</TableCell>
              <TableCell>{orderDetails?.order_id}</TableCell>
            </TableRow>
            {orderDetails?.invoice_no && (
              <TableRow>
                <TableCell>Invoice No:</TableCell>
                <TableCell>{orderDetails?.invoice_no}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>Order Date:</TableCell>
              <TableCell>
                {new Date(orderDetails?.order_submitted_at).toLocaleString()}
              </TableCell>
            </TableRow>

            {/* {orderDetails?.paid_at && (
              <TableRow>
                <TableCell>Paid at:</TableCell>
                <TableCell>
                  {new Date(orderDetails?.paid_at).toLocaleString()}
                </TableCell>
              </TableRow>
            )} */}
            <TableRow>
              <TableCell>Expected Delivery:</TableCell>
              <TableCell>{orderDetails?.expected_delivery}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Slot:</TableCell>
              <TableCell>{orderDetails?.slot_timing}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Order Total:</TableCell>
              <TableCell>
                &#8377; {orderDetails?.order_overall_totall}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Order Status:</TableCell>
              <TableCell>{orderDetails?.order_status}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Delivery Type:</TableCell>
              <TableCell style={{ fontWeight: "bold", color: "blue" }}>
                {orderDetails?.delivery_mode}
              </TableCell>
            </TableRow>

            {orderDetails?.hold === 1 && (
              <>
                <TableRow>
                  <TableCell>Hold Reason:</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        openCommentPopUp(orderDetails?.hold_reason)
                      }
                      style={{ height: "20px" }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Hold By:</TableCell>
                  <TableCell>{orderDetails?.hold_by}</TableCell>
                </TableRow>
              </>
            )}
            {orderDetails?.checkout_tracking_id && (
              <TableRow>
                <TableCell>Checkout Tracking ID:</TableCell>
                <TableCell>{orderDetails?.checkout_tracking_id}</TableCell>
              </TableRow>
            )}
            {orderDetails?.message && (
              <TableRow>
                <TableCell>Message:</TableCell>
                <TableCell>
                  <Button
                    onClick={() => openCommentPopUp(orderDetails?.message)}
                    sx={{ fontSize: "14px", textTransform: "capitalize" }}
                  >
                    View Message
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    );
  };
  const BillingDetails = () => {
    return (
      <div className="card bg-light p-3">
        <Table className="w-100 table table-sm table-borderless text-center table-hover table-light">
          <TableHead>
            <TableRow className="thead">
              <TableCell colSpan={2}>
                <h5>Bill Summmary</h5>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="order-summary">
            <TableRow>
              <TableCell>Billing Name:</TableCell>
              <TableCell className="align-right">
                {orderDetails?.billing_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Billing Mobile No.:</TableCell>
              <TableCell className="align-right">
                {orderDetails?.billing_mobile_number}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cake Total:</TableCell>
              <TableCell className="align-right">
                ₹{" "}
                <b>
                  {(
                    +orderDetails?.order_price_details?.cake_subtotal || 0
                  ).toFixed(2)}
                </b>
              </TableCell>
            </TableRow>
            {orderDetails?.promo_code_value > 0 && (
              <TableRow>
                <TableCell>
                  Discount: (
                  <span style={{ color: "black", fontWeight: "bold" }}>
                    {orderDetails?.promo_code}
                  </span>
                  ) &nbsp;
                  {orderDetails?.promo_type == "PERCENTAGE" && (
                    <span style={{ color: "red" }}>
                      {orderDetails?.promo_code_percentage}%
                    </span>
                  )}
                </TableCell>
                <TableCell className="align-right">
                  <b>- ₹ {(+orderDetails?.promo_code_value || 0).toFixed(2)}</b>
                </TableCell>
              </TableRow>
            )}
            {orderDetails?.order_price_details?.addon_total_amount > 0 && (
              <TableRow>
                <TableCell>Addon Total:</TableCell>
                <TableCell className="align-right">
                  ₹{" "}
                  <b>
                    {(
                      +orderDetails?.order_price_details?.addon_total_amount ||
                      0
                    ).toFixed(2)}
                  </b>
                </TableCell>
              </TableRow>
            )}
            {orderDetails?.deliver_fee_type == 0 &&
              orderDetails?.deliver_fee > 0 && (
                <TableRow>
                  <TableCell>Delivery Fee:</TableCell>
                  <TableCell className="align-right">
                    ₹ <b>{(+orderDetails?.deliver_fee || 0).toFixed(2)}</b>
                  </TableCell>
                </TableRow>
              )}
            {orderDetails?.deliver_fee_type == 1 &&
              orderDetails?.actual_delivery_fee > 0 && (
                <TableRow>
                  <TableCell>
                    Delivery Fee: &nbsp;<span className="free-tag">FREE</span>
                  </TableCell>
                  <TableCell
                    className="align-right"
                    style={{ textDecoration: "line-through" }}
                  >
                    ₹{" "}
                    <b>
                      {(+orderDetails?.actual_delivery_fee || 0).toFixed(2)}
                    </b>
                  </TableCell>
                </TableRow>
              )}
            <TableRow style={{ borderTop: "1px solid gray" }}>
              <TableCell>Taxable:</TableCell>
              <TableCell className="align-right">
                ₹{" "}
                <b>
                  {(
                    +orderDetails?.order_price_details?.taxable_amount || 0
                  ).toFixed(2)}
                </b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>GST:</TableCell>
              <TableCell className="align-right">
                ₹{" "}
                <b>
                  {(
                    +orderDetails?.order_price_details?.gst_amount || 0
                  ).toFixed(2)}
                </b>
              </TableCell>
            </TableRow>
            <TableRow style={{ borderTop: "1px solid gray" }}>
              <TableCell>Total:</TableCell>
              <TableCell className="align-right">
                ₹{" "}
                <b>
                  {(
                    +orderDetails?.order_price_details?.total_amount || 0
                  ).toFixed(2)}
                </b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  };
  return (
    <>
      <Grid container columnSpacing={{ xs: 5 }} id="order-summary">
        <Grid
          item
          xs={4}
          sx={{ display: "flex", flexDirection: "column", gap: 3 }}
        >
          <Summary />
          <PaymentDetails />
        </Grid>
        <Grid
          item
          xs={4}
          sx={{ display: "flex", flexDirection: "column", gap: 3 }}
        >
          <TableContainer>
            {orderDetails?.delivery_mode == "Door Step Delivery" ? (
              <DoorStepShippingAddressDetails />
            ) : (
              <PickUpShippingDetails />
            )}
          </TableContainer>
          <CustomerDetails />
        </Grid>
        <Grid item xs={4}>
          <BillingDetails />
        </Grid>
      </Grid>
      <MessageDialog />
      <RazorPayOrderDetailsDialog />
      <MigrateOrderDialog />
    </>
  );
}

export default OrderSummary;
