import React, { useEffect, useState } from "react";

import {
  Alert,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import Loader from "../../../components/loader/Loader";
import { hideConfirmModal } from "../../../redux/counterSlice";
import DispatchOrderItem from "./ShopOrderItem";
import ShopOrderItem from "./ShopOrderItem";

function ShopPanel() {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(true);
  const [orderList, setOrderList] = useState([]);
  const [shop, setShop] = useState(0);
  const [shopList, setShopList] = useState();

  const loadInitialData = () => {
    const url = "/getAllActiveShopDetails";
    setLoader(true);
    axios
      .get(url)
      .then(function (response) {
        setShopList(response?.data?.data);
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
        dispatch(hideConfirmModal({ msg: "" }));
      });
  };

  const listAllDispatchOrders = () => {
    if (shop > 0) {
      const url = "/getShopOrderById/" + shop;
      setLoader(true);
      axios
        .get(url)
        .then(function (response) {
          setOrderList(response?.data?.data);
        })
        .catch(function (err) {
          toast.error(err?.message);
        })
        .finally(() => {
          setLoader(false);
          dispatch(hideConfirmModal({ msg: "" }));
        });
    }
  };

  const handleShopChange = (value) => {
    setShop(value);
    sessionStorage.setItem("shop_selected_location", value);
  };
  useEffect(() => {
    loadInitialData();
  }, []);

  useEffect(() => {
    listAllDispatchOrders();
  }, [shop]);
  useEffect(() => {
    const selectedLocation = sessionStorage.getItem("shop_selected_location");
    setShop(selectedLocation);
  }, [orderList]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <h4 className="text-dark">Shop Panel</h4>
          <Paper
            elevation={1}
            sx={{
              p: 3,
              margin: "auto",
              marginTop: "15px",
              flexGrow: 1,
            }}
          >
            <Grid
              container
              columnSpacing={{ xs: 4 }}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <FormControl margin="normal" className="w-100">
                  <InputLabel id="status_dropdown_label">Shop</InputLabel>
                  <Select
                    labelId="status_dropdown_label"
                    id={`status_dropdown_select`}
                    value={shop}
                    label="Update"
                    onChange={(e) => handleShopChange(e?.target?.value)}
                  >
                    <MenuItem value={0}>--Select Shop--</MenuItem>
                    {shopList?.map((shop) => (
                      <MenuItem value={shop.shop_details_id}>
                        {shop.shop_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                  spacing={3}
                >
                  {orderList?.length > 0 ? (
                    orderList?.map((shop) => {
                      return (
                        <Grid item xs={4}>
                          <ShopOrderItem shop={shop} />
                        </Grid>
                      );
                    })
                  ) : (
                    <Stack sx={{ width: "100%", mt: 3, p: 3 }} spacing={2}>
                      <Alert severity="warning">
                        <h6>No Data Found..</h6>
                      </Alert>
                    </Stack>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
    </>
  );
}

export default ShopPanel;
