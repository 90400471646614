import React, { useEffect, useState } from "react";

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  FormControl,
  Select,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  TextField,
  Chip,
} from "@mui/material";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import toast from "react-hot-toast";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import Loader from "../../../../components/loader/Loader";
import { Controller, useForm } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import { yupResolver } from "@hookform/resolvers/yup";
import { orange } from "@mui/material/colors";
import { FormInputText } from "../../../../components/form-components/FormInputText";
import SaveIcon from "@mui/icons-material/Save";
import { lastHomeSectionSchema } from "../../../../app/schema";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { FormInputDropdown } from "../../../../components/form-components/FormInputDropdown";
import { hideConfirmModal, showModal } from "../../../../redux/counterSlice";
import GeneralImageUpload from "../../../../components/form-components/GeneralImageUpload";

function SectionFive({ regionId }) {
  const dispatch = useDispatch();
  const { subCategoryList } = useSelector((state) => state.counter);
  const sectionId = 6;

  const methods = useForm({
    resolver: yupResolver(lastHomeSectionSchema),
  });
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = methods;

  const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [productList, setProductList] = useState([]);

  const [loader, setLoader] = useState(false);
  const [activeList, setActiveList] = useState([]);
  const [heading, setHeading] = useState();

  const columns = [
    {
      name: "id",
      label: "S. No",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return <p>{+dataIndex + 1}</p>;
        },
      },
    },
    {
      name: "image",
      label: "Image",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const imageUrl = activeList?.products?.[dataIndex]?.image;
          return imageUrl ? (
            <PhotoProvider>
              <PhotoView key={dataIndex} src={imageUrl}>
                <img
                  src={imageUrl}
                  alt=""
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                />
              </PhotoView>
            </PhotoProvider>
          ) : (
            <h3>-</h3>
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "type",
      label: "Type",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const type = activeList?.products?.[dataIndex]?.type;
          return (
            <Chip
              label={`${type == "SC" ? "Sub Category" : "Product"}`}
              color="secondary"
              size="small"
            />
          );
        },
      },
    },
    {
      name: "Actions",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div className="actionBtns">
              <Button
                aria-label="edit"
                onClick={() => {
                  setSelectedRow(() => ({
                    ...activeList?.products?.[dataIndex],
                  }));
                  setOpenImage(true);
                }}
              >
                <AddPhotoAlternateIcon
                  sx={{ color: orange[500] }}
                  fontSize="medium"
                />
              </Button>
              <Button
                aria-label="edit"
                onClick={() =>
                  editClickHandler(activeList?.products?.[dataIndex])
                }
              >
                <EditIcon fontSize="medium" sx={{ color: "secondary" }} />
              </Button>
              <Button
                aria-label="delete"
                onClick={() => {
                  setSelectedRow(() => ({
                    action: () =>
                      deleteHandler(activeList?.products?.[dataIndex]?.id),
                    confirmLabel: "Delete",
                  }));
                  setConfirmOpen(true);
                }}
              >
                <DeleteIcon fontSize="medium" sx={{ color: "red" }} />
              </Button>
            </div>
          );
        },
      },
    },
  ];
  const handleClose = () => {
    setOpen(false);
    setOpenImage(false);
    setSelectedRow(null);
    reset();
  };
  const editClickHandler = (item) => {
    setOpen(true);
    setSelectedRow({ ...item });
  };
  const onSubmit = (data) => {
    saveForm(data);
  };
  const deleteHandler = async (id) => {
    try {
      let response;
      if (id) {
        response = await axios.get(`/deleteLastHomeSection/${id}/${regionId}`);
        if (!response?.data?.error) {
          toast.success(response?.data?.message);
          loadInitialData();
          setOpen(false);
          setSelectedRow((prev) => null);
          reset();
        } else {
          let errDesc = "";
          if (response?.data?.data) {
            Object.values(response?.data?.data).map((error, index) => {
              errDesc =
                index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
            });
          } else {
            errDesc = response?.data?.message;
          }

          dispatch(
            showModal({
              msg: errDesc,
              error: response?.data?.error,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      setLoader(false);
      setOpen(false);
      setConfirmOpen(false);
    }
  };
  const saveHeading = async () => {
    if (heading) {
      axios
        .get(`/updateSectionHeading/${sectionId}/${heading}/${regionId}`)
        .then(function (response) {
          if (!response?.data?.error) {
            toast.success(response?.data?.message);
            loadInitialData();
          } else {
            let errDesc = "";
            if (response?.data?.data) {
              Object.values(response?.data?.data).map((error, index) => {
                errDesc =
                  index === 0
                    ? errDesc + error + "\n "
                    : errDesc + error + "\n ";
              });
            } else {
              errDesc = response?.data?.message;
            }

            dispatch(
              showModal({
                msg: errDesc,
                error: response?.data?.error,
              })
            );
          }
        })
        .catch((error) => {
          dispatch(
            showModal({
              msg: error?.message,
              error: true,
            })
          );
        });
    }
  };

  const saveForm = async (data) => {
    setLoader(true);
    try {
      let response;
      response = await axios.post("/updateLastHomeSection", {
        ...data,
        id: selectedRow?.id,
        region_id: regionId,
      });

      if (!response?.data?.error) {
        toast.success(response?.data?.message);
        loadInitialData();

        setSelectedRow(() => null);
        reset();
      } else {
        let errDesc = "";
        if (response?.data?.data) {
          Object.values(response?.data?.data).map((error, index) => {
            errDesc =
              index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
          });
        } else {
          errDesc = response?.data?.message;
        }

        dispatch(
          showModal({
            msg: errDesc,
            error: response?.data?.error,
          })
        );
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      setLoader(false);
      setOpen(false);
    }
  };
  const type = watch("type", selectedRow?.type);
  const CreateEditDialog = React.memo(() => {
    return (
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
        <DialogTitle className="text-primary">
          {selectedRow ? "Edit" : "New"}
        </DialogTitle>
        <DialogContent>
          <form key={1}>
            <Grid container columnSpacing={{ xs: 5 }}>
              <Grid item xs={12}>
                <FormInputText
                  name="name"
                  control={control}
                  label="Name"
                  errors={errors}
                  mandatory="true"
                  defaultValue={selectedRow?.name}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  defaultValue={selectedRow?.type}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <FormControl
                        fullWidth
                        error={errors?.["type"]?.message ? true : false}
                        margin="normal"
                      >
                        <InputLabel id="type_label"> Type</InputLabel>
                        <Select
                          labelId="type_label"
                          id={`type_select`}
                          value={value || selectedRow?.type}
                          label="Type"
                          onChange={onChange}
                        >
                          <MenuItem value={""} key={0}>
                            --SELECT--
                          </MenuItem>
                          <MenuItem value="P" key="P">
                            Product
                          </MenuItem>
                          <MenuItem value="SC" key="SC">
                            Sub Category
                          </MenuItem>
                        </Select>
                      </FormControl>
                    );
                  }}
                  control={control}
                  name={"type"}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputDropdown
                  name="ref_id"
                  control={control}
                  label={type == "P" ? "Products" : "Sub Category"}
                  type="single"
                  options={
                    type == "P"
                      ? productList?.map((item) => ({
                          id: item?.id,
                          name: `${item?.product_name}`,
                        }))
                      : subCategoryList?.map((item) => ({
                          id: item?.id,
                          name: `${item?.sub_category_name}`,
                        }))
                  }
                  errors={errors}
                  defaultValue={selectedRow?.ref_id}
                  mandatory="true"
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
          <Button
            // disabled={!isValid}
            color="primary"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  });

  const UpdateImageDialog = () => {
    return (
      <Dialog open={openImage} onClose={handleClose} maxWidth={"xs"}>
        <DialogTitle className="text-primary">Update Image</DialogTitle>
        <Divider variant="middle" className="bg-primary" />

        <DialogContent>
          <Grid container columnSpacing={{ xs: 4 }}>
            <Grid item xs={12}>
              <GeneralImageUpload
                status={selectedRow?.image}
                name={selectedRow?.id?.toString()}
                control={control}
                label={selectedRow?.name}
                setValue={setValue}
                type="text"
                defaultValue={selectedRow?.image}
                loadInitialData={loadInitialData}
                url="imageUpdateHomeSection"
                formDataKey={{ id: "id", image: "image" }}
                dimension="Aspect Ratio 1:1"
                setOpenImage={setOpenImage}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const options = {
    selectableRows: false,
    filter: false,
    download: true,
    viewColumns: true,
    customSearch: false,
    print: true,
  };
  const loadInitialData = async () => {
    try {
      setLoader(true);
      const [homeResponse, productResponse] = await axios.all([
        axios.get("/getAppHomeData/" + regionId),
        axios.get(`/getActiveProductByRegion/${regionId}`),
      ]);
      setActiveList(homeResponse?.data?.data?.sections?.[4]);
      setProductList(productResponse?.data?.data);
    } catch (error) {
      console.error("Error loading initial data:", error);
    } finally {
      setLoader(false);
      dispatch(hideConfirmModal({ msg: "" }));
    }
  };

  useEffect(() => {
    loadInitialData();
  }, []);
  useEffect(() => setHeading(activeList?.headings), [activeList]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <Paper
            elevation={1}
            sx={{
              margin: "15px auto",
              flexGrow: 1,
              p: 3,
              pt: 0,
              width: "100%",
            }}
          >
            <form key={2}>
              <Grid
                container
                spacing={2}
                columnSpacing={{ xs: 5 }}
                style={{ display: "flex" }}
              >
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label={"Heading"}
                    variant="outlined"
                    onChange={(e) => setHeading(e.target?.value)}
                    value={heading}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={3} margin={"auto 0px"}>
                  <Button
                    disabled={heading?.length <= 5}
                    color="primary"
                    variant="contained"
                    onClick={() => saveHeading()}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
          <MUIDataTable
            data={activeList?.products}
            columns={columns}
            options={options}
            title={
              activeList?.products?.length < 4 && (
                <Button
                  className="mx-2"
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setSelectedRow(null);
                    setOpen(true);
                    reset();
                  }}
                  icon={<AddIcon />}
                >
                  New
                </Button>
              )
            }
          />
        </>
      )}
      <CreateEditDialog />
      <UpdateImageDialog />
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {selectedRow?.confirmLabel} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmOpen(false)}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={() => selectedRow?.action()}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SectionFive;
