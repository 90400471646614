import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Chip from "@mui/material/Chip";
import {
  CircleNotifications,
  NotificationsActiveOutlined,
} from "@mui/icons-material";
import { Badge, Typography } from "@mui/material";

export default function Notification() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notificationList = useSelector(
    (state) => state.counter?.notificationList
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      {notificationList?.length > 0 && (
        <>
          <Box
            sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
          >
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2, color: "white" }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Badge badgeContent={notificationList?.length} color="success">
                  <CircleNotifications fontSize="medium" />
                </Badge>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            sx={{ px: 2 }}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                minWidth: 350,
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <Typography
              variant="body1"
              color="text.secondary"
              className="font-weight-bold px-3 text-center text-dark"
            >
              Notification
            </Typography>
            {notificationList?.map((item, index) => (
              <MenuItem
                className="d-flex align-items-center justify-content-start gap-2 my-3"
                key={index}
                onClick={() => navigate(`/order-list/${item?.id}`)}
              >
                <img
                  width={48}
                  height={48}
                  style={{ borderRadius: 8 }}
                  src={item?.imageUrl}
                  alt="user avatar"
                />
                <div>
                  <Typography variant="body2" color="text.secondary">
                    {item?.title}
                  </Typography>

                  <Typography variant="body2" color="text.secondary">
                    {`Order ID: #${item?.id}`}
                  </Typography>
                </div>
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </React.Fragment>
  );
}
