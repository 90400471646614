import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormInputText } from "../../../../components/form-components/FormInputText";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { sectionOneSchema } from "../../../../app/schema";
import Loader from "../../../../components/loader/Loader";
import MUIDataTable from "mui-datatables";
import AddIcon from "@mui/icons-material/Add";
import toast from "react-hot-toast";
import axios from "axios";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { useDispatch } from "react-redux";
import {
  hideConfirmModal,
  showConfirmModal,
  showModal,
} from "../../../../redux/counterSlice";
import FlavourSectionBackgroundUpload from "../../../../components/form-components/FlavourSectionBackgroundUpload";

function SectionThree({ regionId }) {
  const dispatch = useDispatch();
  const sectionId = 4;
  const [loader, setLoader] = useState(false);
  const [activeList, setActiveList] = useState([]);
  const [inactiveList, setInactiveList] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [activeBtn, setActiveBtn] = useState("ACTIVE");
  const [searchText, setSearchText] = useState("");
  const [openImage, setOpenImage] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const methods = useForm({
    resolver: yupResolver(sectionOneSchema),
  });
  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = methods;
  const toggleStatus = (item) => {
    setLoader(true);
    if (item?.image_url) {
      axios
        .get(`/updateProductIds/${sectionId}/${item?.flavour_id}/${regionId}`)

        .then(function () {
          loadInitialData();
        })
        .catch(function (err) {
          toast.error(err?.message);
        })
        .finally(() => {
          setLoader(false);
        });
    } else {
      toast.error("Please upload image");
      dispatch(hideConfirmModal({ msg: "" }));
    }
  };

  const columns = [
    {
      name: "flavour_id",
      label: "S. No",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return <p>{+dataIndex + 1}</p>;
        },
      },
    },
    {
      name: "app_product_image",
      label: "Image",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const imageUrl = tableList?.[dataIndex]?.image_url;
          return imageUrl ? (
            <PhotoProvider>
              <PhotoView key={dataIndex} src={imageUrl}>
                <img
                  src={imageUrl}
                  alt=""
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                />
              </PhotoView>
            </PhotoProvider>
          ) : (
            <h3>-</h3>
          );
        },
      },
    },

    {
      name: "flavour_name",
      label: "Flavour Name",
    },
    {
      name: "product_count",
      label: "Product Count",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const count = tableList[dataIndex]?.product_count;
          return <Chip label={count} color="primary" />;
        },
      },
    },
    {
      name: "active_status",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const status = activeBtn === "ACTIVE" ? 1 : 0;
          return (
            <Switch
              size="small"
              color={status === 1 ? "success" : "error"}
              checked={status === 1 ? true : false}
              onChange={() =>
                dispatch(
                  showConfirmModal({
                    msg:
                      status === 1
                        ? "remove from preview"
                        : "add this to preview",
                    action: () => toggleStatus(tableList[dataIndex]),
                  })
                )
              }
              style={{ marginBottom: "5px" }}
            />
          );
        },
      },
    },
  ];
  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    viewColumns: false,
    print: false,
    searchText: searchText,
    onSearchChange: (searchValue) => setSearchText(searchValue),
  };

  const onSubmit = (data) => {
    saveHeading(data);
  };
  const saveHeading = async (data) => {
    if (data?.heading) {
      axios
        .get(`/updateSectionHeading/${sectionId}/${data?.heading}/${regionId}`)

        .then(function (response) {
          if (!response?.data?.error) {
            toast.success(response?.data?.message);
            loadInitialData();
          } else {
            let errDesc = "";
            if (response?.data?.data) {
              Object.values(response?.data?.data).map((error, index) => {
                errDesc =
                  index === 0
                    ? errDesc + error + "\n "
                    : errDesc + error + "\n ";
              });
            } else {
              errDesc = response?.data?.message;
            }

            dispatch(
              showModal({
                msg: errDesc,
                error: response?.data?.error,
              })
            );
          }
        })
        .catch((error) => {
          dispatch(
            showModal({
              msg: error?.message,
              error: true,
            })
          );
        });
    }
  };
  const loadInitialData = async () => {
    try {
      setLoader(true);
      const sectionId = 4;
      const [homeResponse, productResponse] = await axios.all([
        axios.get("/getAppHomeData/" + regionId),
        axios.get(`/product/inactive/${sectionId}/${regionId}`),
      ]);
      setActiveList(homeResponse?.data?.data?.sections?.[2]);
      setInactiveList(productResponse?.data?.data);
      handleClose();
    } catch (error) {
      console.error("Error loading initial data:", error);
    } finally {
      setLoader(false);
      dispatch(hideConfirmModal({ msg: "" }));
    }
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  useEffect(() => {
    if (!loader) {
      setTableList(
        activeBtn === "ACTIVE" ? activeList?.products : inactiveList
      );
    }
  }, [activeBtn, loader, activeList, inactiveList]);

  const handleClose = () => {
    setOpenImage(false);
    setSelectedRow(null);
    reset();
  };
  const UpdateImageDialog = () => {
    return (
      <Dialog open={openImage} onClose={handleClose} maxWidth={"xs"}>
        <DialogTitle className="text-primary">Flavour Background</DialogTitle>
        <Divider variant="middle" />
        <DialogContent>
          <Grid container columnSpacing={{ xs: 4 }}>
            <Grid item xs={12}>
              <FlavourSectionBackgroundUpload
                // status={formData?.active_status}
                name={"home_flavour_bg"}
                control={control}
                label={"Flavour Background"}
                setValue={setValue}
                type="text"
                defaultValue={activeList?.home_flavour_bg?.image_url}
                loadInitialData={loadInitialData}
                regionId={regionId}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  return loader ? (
    <Loader />
  ) : (
    <>
      <Paper
        elevation={1}
        sx={{
          margin: "15px auto",
          flexGrow: 1,
          p: 3,
          pt: 0,
          width: "100%",
        }}
      >
        <Grid
          container
          spacing={2}
          columnSpacing={{ xs: 5 }}
          style={{ display: "flex" }}
        >
          <Grid item xs={5}>
            <FormInputText
              name="heading"
              control={control}
              label="Heading"
              errors={errors}
              mandatory="true"
              defaultValue={activeList?.headings}
            />
          </Grid>

          <Grid
            item
            xs={7}
            margin={"auto 0px"}
            className="d-flex align-item-center"
          >
            <Button
              disabled={watch("heading")?.length === 5}
              color="primary"
              variant="contained"
              className="mx-2"
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
            <Button
              color="primary"
              variant="outlined"
              className="mx-2"
              onClick={() => {
                // setSelectedRow({  });
                setOpenImage(true);
              }}
            >
              Background
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <MUIDataTable
        data={tableList}
        columns={columns}
        options={options}
        title={
          <div>
            <Button
              className="mx-2"
              size="small"
              variant={activeBtn === "ACTIVE" ? "contained" : "outlined"}
              onClick={() => setActiveBtn("ACTIVE")}
              icon={<AddIcon />}
            >
              Active
            </Button>
            <Button
              className="mx-2"
              size="small"
              color="primary"
              variant={activeBtn === "ACTIVE" ? "outlined" : "contained"}
              onClick={() => setActiveBtn("INACTIVE")}
              icon={<AddIcon />}
            >
              In-Active
            </Button>
          </div>
        }
      />
      <UpdateImageDialog />
    </>
  );
}

export default SectionThree;
