import React, { useEffect, useState } from "react";

import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Switch,
  Typography,
  colors,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../../components/loader/Loader";
import { useForm } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import { yupResolver } from "@hookform/resolvers/yup";
import { grey, orange } from "@mui/material/colors";
import { FormInputText } from "../../../components/form-components/FormInputText";
import SaveIcon from "@mui/icons-material/Save";
import { subCategorySchema } from "../../../app/schema";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CategoryImageUpload from "../../../components/form-components/CategoryImageUpload";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { FormInputDropdown } from "../../../components/form-components/FormInputDropdown";
import {
  handlePageSettings,
  handleProductSearch,
  handleSearchSetting,
  hideConfirmModal,
  showConfirmModal,
  showModal,
} from "../../../redux/counterSlice";
import GeneralImageUpload from "../../../components/form-components/GeneralImageUpload";
import { useNavigate, useParams } from "react-router-dom";
import {
  AddSharp,
  ThumbDown,
  ThumbDownAltOutlined,
  ThumbsUpDown,
  ThumbUp,
  ThumbUpAltOutlined,
} from "@mui/icons-material";
import { extractUniqueRegions } from "../../../app/utlils";

function Products() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    taxList,
    categoryList,
    settings,
    pageSettings: {
      product: { pageSize, pageNo },
    },
  } = useSelector((state) => state.counter);

  const methods = useForm({
    resolver: yupResolver(subCategorySchema),
  });
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    Valid,
    formState: { errors, isDirty, isValid },
  } = methods;

  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchText, setSearchText] = useState("");

  const loadInitialData = () => {
    setLoader(true);
    axios
      .get("/product")
      .then(function (response) {
        setFormData(response?.data?.data);
        setLoader(false);
      })
      .catch(function (err) {
        toast.error(err?.message);
      });
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  useEffect(() => {
    setSearchText(settings?.product || "");
  }, [settings]);
  const uniqueCategoryNames = Array.from(
    new Set(formData.map((item) => item.category?.category_name))
  );
  const uniqueSubCategoryNames = Array.from(
    new Set(formData.map((item) => item.sub_category?.sub_category_name))
  );

  const uniqueRegions = extractUniqueRegions(formData);
  const columns = [
    {
      name: "id",
      label: "S. No",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return <p>{+dataIndex + 1}</p>;
        },
        filter: false,
      },
    },

    {
      name: "images",
      label: "Image",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          const imageUrl = formData[dataIndex]?.images?.[0]?.app_product_image;
          return imageUrl ? (
            <PhotoProvider>
              <PhotoView key={dataIndex} src={imageUrl}>
                <img
                  src={imageUrl}
                  alt=""
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                />
              </PhotoView>
            </PhotoProvider>
          ) : (
            <h3>-</h3>
          );
        },
      },
    },

    {
      name: "product_name",
      label: "Product Name",
      options: {
        filter: false,
      },
    },
    {
      name: "category.category_name",
      label: "Category",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const categoryName = formData[dataIndex]?.category?.category_name;
          return categoryName != null ? categoryName : "-";
        },
        filter: true,
        filterOptions: {
          names: uniqueCategoryNames,
        },
      },
    },
    {
      name: "sub_category.sub_category_name",
      label: "Sub Category",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const subCategoryName =
            formData[dataIndex]?.sub_category?.sub_category_name;
          return subCategoryName != null ? subCategoryName : "-";
        },
        filter: true,
        filterOptions: {
          names: uniqueSubCategoryNames, // Provide the unique category names for filtering
        },
      },
    },
    {
      name: "active_status",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const imageUrl = formData[dataIndex]?.images?.[0]?.app_product_image;
          const status = formData[dataIndex]?.active_status;
          return (
            <Switch
              disabled={!imageUrl}
              size="small"
              color={status === 1 ? "success" : "error"}
              checked={status === 1 ? true : false}
              onChange={() =>
                dispatch(
                  showConfirmModal({
                    msg: status === 1 ? "Deactivate" : "Activate",
                    action: () => toggleStatus(formData[dataIndex]?.id),
                  })
                )
              }
              style={{ marginBottom: "5px" }}
            />
          );
        },
        filter: true,
        filterOptions: {
          names: ["Active", "Inactive"],
          logic(value, filters) {
            if (filters.length) {
              if (filters.includes("Active") && +value === 1) return false;
              if (filters.includes("Inactive") && +value === 0) return false;
              return true;
            }
            return false;
          },
        },
      },
    },
    {
      name: "best_selling",
      label: "Best Seller",
      options: {
        filter: true,
        display: "excluded",
        filterOptions: {
          names: ["Best Seller", "Not Best Seller"],
          logic(value, filters) {
            if (filters.length) {
              if (filters.includes("Best Seller") && +value === 1) return false;
              if (filters.includes("Not Best Seller") && +value === 0)
                return false;
              return true;
            }
            return false;
          },
        },
      },
    },
    {
      name: "new_product",
      label: "New Product",
      options: {
        filter: true,
        display: "excluded",
        filterOptions: {
          names: ["New Arrival", "Not New Arrival"],
          logic(value, filters) {
            if (filters.length) {
              if (filters.includes("New Arrival") && +value === 1) return false;
              if (filters.includes("Not New Arrival") && +value === 0)
                return false;
              return true;
            }
            return false;
          },
        },
      },
    },

    {
      name: "region_id",
      label: "Region",
      options: {
        filterType: "multiselect",
        filter: true,
        display: "excluded",
        filterOptions: {
          names: uniqueRegions?.map((item) => item?.name),
          logic(regionIdString, filters) {
            if (filters.length) {
              const regionIds = regionIdString
                .split(",")
                .map((id) => parseInt(id.trim(), 10));

              const selectedRegionIds = filters
                .map((filterName) => {
                  const region = uniqueRegions.find(
                    (region) => region.name === filterName
                  );
                  return region ? region.id : null;
                })
                .filter(Boolean);

              const isRegionPresent = regionIds.some((regionId) =>
                selectedRegionIds.includes(regionId)
              );

              return !isRegionPresent;
            }
            return false;
          },
        },
      },
    },
    {
      name: "Actions",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          const status = formData[dataIndex]?.active_status;
          return (
            <div className="actionBtns">
              <Button
                disabled={status === 1 ? true : false}
                aria-label="edit"
                onClick={() => {
                  searchText != "" && dispatch(handleProductSearch(searchText));
                  navigate("edit-product-image/" + formData[dataIndex]?.id);
                }}
              >
                <AddPhotoAlternateIcon
                  sx={{ color: status === 1 ? grey[600] : orange[500] }}
                  fontSize="medium"
                />
              </Button>
              <Button
                disabled={status === 1 ? true : false}
                aria-label="edit"
                onClick={() => editClickHandler(formData[dataIndex])}
              >
                <EditIcon
                  sx={{ color: status === 1 ? grey[600] : "secondary" }}
                  fontSize="medium"
                />
              </Button>
              <Button
                disabled={status === 1 ? true : false}
                aria-label="edit"
                onClick={() =>
                  dispatch(
                    showConfirmModal({
                      msg: "Delete",
                      action: () => deleteClickHandler(formData[dataIndex]?.id),
                    })
                  )
                }
              >
                <DeleteOutlineOutlinedIcon
                  sx={{ color: status === 1 ? grey[600] : "red" }}
                  fontSize="medium"
                />
              </Button>
            </div>
          );
        },
      },
    },
  ];
  const handleClose = () => {
    setOpen(false);
    setOpenImage(false);
    setSelectedRow(null);
    reset();
  };
  const editClickHandler = (item) => {
    searchText != "" && dispatch(handleProductSearch(searchText));
    navigate("edit-product/" + item?.id);
  };
  const onSubmit = (data) => {
    // console.log("data", data);
    saveForm(data);
  };

  const toggleStatus = (id) => {
    setLoader(true);
    axios
      .get("/productSwitch/" + id)
      .then(function (response) {
        loadInitialData();
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
        dispatch(hideConfirmModal({ msg: "" }));
      });
  };
  const saveForm = async (data) => {
    setLoader(true);
    try {
      let response;
      if (selectedRow?.id) {
        response = await axios.put("/product/" + selectedRow?.id, data);
      } else {
        response = await axios.post("/product", data);
      }

      if (!response?.data?.error) {
        toast.success(response?.data?.message);
        loadInitialData();
        setOpen(false);
        setSelectedRow((prev) => null);
        reset();
      } else {
        let errDesc = "";
        if (response?.data?.data) {
          Object.values(response?.data?.data).map((error, index) => {
            errDesc =
              index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
          });
        } else {
          errDesc = response?.data?.message;
        }

        dispatch(
          showModal({
            msg: errDesc,
            error: response?.data?.error,
          })
        );
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      setLoader(false);
      dispatch(hideConfirmModal({ msg: "" }));
    }
  };
  const deleteClickHandler = async (id) => {
    try {
      let response;
      if (id) {
        response = await axios.delete("/product/" + id);
        if (!response?.data?.error) {
          toast.success(response?.data?.message);
          loadInitialData();
          setOpen(false);
          setSelectedRow((prev) => null);
          reset();
        } else {
          let errDesc = "";
          if (response?.data?.data) {
            Object.values(response?.data?.data).map((error, index) => {
              errDesc =
                index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
            });
          } else {
            errDesc = response?.data?.message;
          }

          dispatch(
            showModal({
              msg: errDesc,
              error: response?.data?.error,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      dispatch(hideConfirmModal({ msg: "" }));
    }
  };

  const CreateEditDialog = React.memo(() => {
    return (
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"}>
        <DialogTitle className="text-primary">
          {selectedRow ? "Edit Product" : "New Product"}
        </DialogTitle>
        <DialogContent>
          <Grid container columnSpacing={{ xs: 5 }}>
            <Grid item xs={12}>
              <FormInputDropdown
                name="category_id"
                control={control}
                label="Category"
                type="single"
                options={categoryList?.map((item) => ({
                  id: item?.id,
                  name: item?.category_name,
                }))}
                errors={errors}
                defaultValue={selectedRow?.category_id}
                mandatory="true"
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name="sub_category_name"
                control={control}
                label="Sub Category Name"
                errors={errors}
                mandatory="true"
                defaultValue={selectedRow?.sub_category_name}
              />
            </Grid>

            <Grid item xs={12}>
              <FormInputText
                name="sub_category_description"
                control={control}
                label="Sub Category Description"
                errors={errors}
                mandatory="true"
                defaultValue={selectedRow?.sub_category_description}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInputText
                name="hsn"
                control={control}
                label="HSN Code"
                errors={errors}
                mandatory="true"
                defaultValue={selectedRow?.hsn}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInputDropdown
                name="tax_id"
                control={control}
                label="Tax"
                type="single"
                options={taxList?.map((item) => ({
                  id: item?.tax_id,
                  name: `${item?.tax_percentage} %`,
                }))}
                errors={errors}
                defaultValue={selectedRow?.tax_id}
                mandatory="true"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
          <Button
            disabled={!isValid}
            color="primary"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  });

  const UpdateImageDialog = () => {
    return (
      <Dialog open={openImage} onClose={handleClose} maxWidth={"xs"}>
        <DialogTitle className="text-primary">Update Image</DialogTitle>
        <Divider variant="middle" className="bg-primary" />

        <DialogContent>
          <Grid container columnSpacing={{ xs: 4 }}>
            <Grid item xs={12}>
              <GeneralImageUpload
                status={selectedRow?.sub_category_image}
                name={selectedRow?.id?.toString()}
                control={control}
                label={selectedRow?.category_name}
                setValue={setValue}
                type="text"
                defaultValue={selectedRow?.sub_category_image}
                loadInitialData={loadInitialData}
                url="imageUpdateSubCategory"
                formDataKey={{ id: "id", image: "sub_category_image" }}
                dimension="220 x 160"
                setOpenImage={setOpenImage}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const options = {
    enableNestedDataAccess: ".",
    selectableRows: false,
    filter: false,
    download: false,
    viewColumns: true,
    print: false,
    searchText: searchText,
    onSearchChange: (searchValue) => setSearchText(searchValue),
    onSearchClose: () => dispatch(handleProductSearch("")),
    rowsPerPage: pageSize,
    page: pageNo,
    filter: true,
    onChangeRowsPerPage: (numberOfRows) =>
      dispatch(
        handlePageSettings({
          screen: "product",
          pageSize: numberOfRows,
          pageNo: pageNo,
        })
      ),
    onChangePage: (currentPage) =>
      dispatch(
        handlePageSettings({
          screen: "product",
          pageSize: pageSize,
          pageNo: currentPage,
        })
      ),
  };
  let activeCounter = 0;
  let inActiveCounter = 0;
  formData?.map((item) =>
    item?.active_status == 1 ? activeCounter++ : inActiveCounter++
  );

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <h4 className="text-dark">Products</h4>
            <Stack direction="row" spacing={1}>
              <Chip
                sx={{ p: 1 }}
                icon={<ThumbsUpDown />}
                label={formData?.length}
                color="primary"
                size="small"
              />
              <Chip
                sx={{ p: 1 }}
                icon={<ThumbUp />}
                label={activeCounter}
                color="success"
                size="small"
              />
              <Chip
                sx={{ p: 1 }}
                icon={<ThumbDown />}
                label={inActiveCounter}
                color="error"
                size="small"
              />
            </Stack>
          </Box>
          <MUIDataTable
            data={formData?.length > 0 ? formData : []}
            columns={columns}
            options={options}
            title={
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  searchText != "" && dispatch(handleProductSearch(searchText));
                  navigate("new-product");
                }}
                icon={<AddIcon />}
              >
                New Product
              </Button>
            }
          />
        </>
      )}
      <CreateEditDialog />
      <UpdateImageDialog />
    </>
  );
}

export default Products;
